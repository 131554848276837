




import WeblinksTabCC from '/src/class-components/practices/practices-details/WeblinksTabCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    WeblinksTabCC
  }
})
export default class WeblinksTab extends Vue {}
